import React, {useState, useLayoutEffect, useContext} from 'react'
import {Link} from 'react-router-dom'

import ajaxRequest from '../../assets/functions/ajaxRequest.js'
import formatMoney from '../../assets/functions/formatMoney.js'

import Context from './context.jsx'

import MediaUploadsList from './mediaUploadsList.jsx'
import ConsumerBalancesList from './consumerBalancesList.jsx'

import LoadingSpinner from '../shared/utility/loadingSpinner.jsx'

export default function BalancePage({
  setParallaxImage,
  setParallaxImageCover,
  setParallaxImageBlur,
  setParallaxInnerContent,
}) {
  const {consumer, brand, authenticityToken, getConsumer, buttonTextColor} =
    useContext(Context)

  const [paymentMethod, setPaymentMethod] = useState()

  function BalanceParallaxInnerContent() {
    if (!consumer) return <></>
    const balanceAmount = formatMoney(
      consumer.pending_consumer_balance
        ? consumer.pending_consumer_balance.balance_cents
        : 0,
    ).split('.')
    return (
      <div className='container max-w-5xl px-4 z-10 text-center'>
        <h1 className='w-full mb-2 text-3xl md:text-4xl text-center text-white font-bold text-shadow'>
          Balance
        </h1>

        <div className='mb-8 text-center text-green'>
          <span className='text-6xl md:text-8xl font-bold text-shadow'>
            {balanceAmount[0]}
          </span>
          <span className='text-3xl md:text-4xl font-bold underline align-top text-shadow'>
            {balanceAmount[1]}
          </span>
        </div>
      </div>
    )
  }

  useLayoutEffect(() => {
    if (brand) {
      setParallaxImage(brand.banner_background_image)
      setParallaxImageCover(true)
      setParallaxImageBlur(true)
      setParallaxInnerContent(BalanceParallaxInnerContent)
    }

    return () => {
      setParallaxImage(false)
      setParallaxImageCover(false)
      setParallaxImageBlur(false)
      setParallaxInnerContent(false)
    }
  }, [consumer, brand])

  const requiredResources = consumer

  const pendingConsumerBalance = consumer.pending_consumer_balance
  const visaAllowed =
    pendingConsumerBalance && pendingConsumerBalance.balance_cents >= 500

  function createPayout() {
    if (paymentMethod === 'visa' && !visaAllowed) return
    ajaxRequest('POST', '/payout', {
      params: {payment_method: paymentMethod},
      headers: [{'X-CSRF-Token': authenticityToken}],
    })
      .then((response) => {
        getConsumer()
        alert('You have cashed out!')
      })
      .catch((error) => {
        console.error(error)
        alert('Oops! Something went wrong.')
      })
  }

  return (
    <div className='container w-full lgplus:w-3/5 mx-auto py-2 md:py-4 px-4 text-center text-dark'>
      {requiredResources ? (
        <>
          {pendingConsumerBalance ? (
            consumer.disabled ? (
              <DisabledAccountMessage />
            ) : (
              <>
                <div className='flex flex-wrap flex-row gap-4 mb-8 justify-center'>
                  <div
                    className='flex flex-col w-48 border rounded-lg bg-white cursor-pointer transition duration-300 hover:shadow'
                    onClick={() => setPaymentMethod('paypal')}>
                    <div className='flex-grow p-2'>
                      <img
                        className='h-16 mx-auto'
                        src='/assets/images/cashout/payout-option_paypal.svg'
                        alt='PayPal Logo'
                      />
                      <p className='mt-2 text-center'>Cash out with PayPal</p>
                    </div>

                    <div
                      className={`shrink-0 mt-2 py-1 rounded-b-lg text-center ${
                        paymentMethod === 'paypal' ? ' bg-green text-white' : ''
                      }`}>
                      {paymentMethod === 'paypal' ? 'Selected' : 'Select'}
                    </div>
                  </div>

                  <div
                    className='flex flex-col w-48 border rounded-lg bg-white cursor-pointer transition duration-300 hover:shadow'
                    onClick={() => setPaymentMethod('venmo')}>
                    <div className='flex-grow p-2'>
                      <img
                        className='h-16 mx-auto'
                        src='/assets/images/cashout/payout-option_venmo.svg'
                        alt='Venmo Logo'
                      />
                      <p className='mt-2 text-center'>Cash out with Venmo</p>
                    </div>

                    <div
                      className={`shrink-0 mt-2 py-1 rounded-b-lg text-center ${
                        paymentMethod === 'venmo' ? ' bg-green text-white' : ''
                      }`}>
                      {paymentMethod === 'venmo' ? 'Selected' : 'Select'}
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-48 border rounded-lg bg-white cursor-pointer transition duration-300 hover:shadow ${
                      visaAllowed
                        ? ''
                        : 'filter grayscale opacity-60 pointer-events-none'
                    }`}
                    onClick={() => setPaymentMethod('visa')}>
                    <div className='flex-grow p-2'>
                      <img
                        className='h-16 mx-auto'
                        src='/assets/images/cashout/payout-option_visa.svg'
                        alt='Visa Logo'
                      />
                      <p className='mt-2 text-center'>
                        {visaAllowed
                          ? 'Cash out with Visa'
                          : 'Must have a balance of $5.00 to use Visa'}
                      </p>
                    </div>

                    <div
                      className={`shrink-0 mt-2 py-1 rounded-b-lg text-center ${
                        paymentMethod === 'visa' ? ' bg-green text-white' : ''
                      }`}>
                      {paymentMethod === 'visa' ? 'Selected' : 'Select'}
                    </div>
                  </div>
                </div>

                <button
                  onClick={createPayout}
                  disabled={!paymentMethod || consumer.disabled}
                  className={`block w-full sm:w-64 mb-8 mx-auto p-2 rounded-full bg-gray-100 transition duration-300 ${
                    paymentMethod ? 'bg-primary' : 'bg-gray-100'
                  } ${buttonTextColor} text-center hover:shadow`}>
                  Cash Out
                </button>
              </>
            )
          ) : (
            <> </>
          )}

          {pendingConsumerBalance ? (
            <ConsumerBalancesList
              title='Current Balance'
              consumerBalances={[pendingConsumerBalance]}
              initiallyExpanded
            />
          ) : (
            <></>
          )}

          {consumer.submitted_media_uploads.length ? (
            <MediaUploadsList
              title='Pending Submissions'
              mediaUploads={consumer.submitted_media_uploads}
              extraClasses='mb-4'
            />
          ) : (
            <></>
          )}

          {consumer.rejected_media_uploads.length ? (
            <MediaUploadsList
              title='Rejected Submissions'
              mediaUploads={consumer.rejected_media_uploads}
              extraClasses='mb-4'
            />
          ) : (
            <></>
          )}

          {consumer.past_consumer_balances.length ? (
            <ConsumerBalancesList
              title='Account History'
              consumerBalances={consumer.past_consumer_balances}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <LoadingSpinner
          size='12'
          color='text-primary'
          extraClasses='mx-auto'
          wrapperClasses='py-3'
        />
      )}
    </div>
  )
}
function DisabledAccountMessage() {
  return (
    <div className='mx-auto mb-8 p-4 border border-light'>
      <h3 className='text-xl md:text-2xl text-center text-dark uppercase font-bold'>
        Your Account Has been Disabled
      </h3>
      <p className='mb-4 text-sm text-center'>
        Please go to your account page for more details
      </p>
      <Link
        to='/account'
        className={`block w-full sm:w-64 mx-auto p-2 rounded-full transition duration-300 bg-primary text-center ${buttonTextColor} hover:shadow`}>
        View Account
      </Link>
    </div>
  )
}
