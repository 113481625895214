import React from 'react'

import ConsumerBalance from './consumerBalance'

export default function ConsumerBalancesList({
  title,
  consumerBalances,
  initiallyExpanded,
}) {
  return (
    <div className='p-2 mb-4 rounded-lg bg-white shadow'>
      <h1 className='text-xl font-semibold text-gray-700'>{title}</h1>

      {consumerBalances.map((consumerBalance) => {
        return (
          <ConsumerBalance
            key={`consumerBalance_${consumerBalance.hashed_id}`}
            consumerBalance={consumerBalance}
            initiallyExpanded={initiallyExpanded}
          />
        )
      })}
    </div>
  )
}
