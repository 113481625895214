import React from 'react'

import MediaUpload from './mediaUpload'

export default function MediaUploadsList({title, mediaUploads, extraClasses}) {
  return (
    <div className={`p-2 rounded-lg bg-white shadow ${extraClasses}`}>
      <h1 className='text-xl font-semibold text-gray-700'>{title}</h1>

      <ul className='divide-y text-dark'>
        {mediaUploads.map((mediaUpload) => (
          <MediaUpload
            key={`mediaUpload-${mediaUpload.hashed_id}`}
            mediaUpload={mediaUpload}
          />
        ))}
      </ul>
    </div>
  )
}
