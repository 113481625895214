import React, {useState} from 'react'
import moment from 'moment'

import formatMoney from '../../assets/functions/formatMoney.js'

export default function MediaUpload({mediaUpload}) {
  const [expanded, setExpanded] = useState(false)

  return (
    <li>
      <div
        onClick={() => setExpanded(!expanded)}
        className='flex p-2 cursor-pointer'>
        <div className='w-14 flex flex-col justify-center'>
          <div>{moment(mediaUpload.updated_at).format('MMM')}</div>
          <div>{moment(mediaUpload.updated_at).format('D')}</div>
        </div>

        <div className='flex-1 flex flex-col justify-center text-left'>
          <div>{mediaUpload.offer.name}</div>
          {mediaUpload.denial_reason ? (
            <div className='text-xs font-secondary'>
              Denial Reason: {mediaUpload.denial_reason}
            </div>
          ) : (
            <div className='text-xs font-secondary'>
              Status: {mediaUpload.status}
            </div>
          )}
        </div>

        <div className='w-14 flex flex-col justify-center'>
          {formatMoney(mediaUpload.offer.discount_cents)}
        </div>
      </div>

      {expanded ? (
        <div className='flex flex-wrap gap-2 p-2 justify-center'>
          {mediaUpload.media_upload_images.map((mui, index) => {
            return (
              <img
                key={`mediaUploadImage_${mediaUpload.hashed_id}_${index}`}
                src={mui.image_url}
                className='w-64'
                alt='Receipt Image'
              />
            )
          })}
        </div>
      ) : (
        <></>
      )}
    </li>
  )
}
